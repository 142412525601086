import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { ShoppingBag, CheckSquare, X, Edit2 } from "react-feather";
import { useQuery, gql, useMutation } from "@apollo/client";
import { numberFormat } from "../../helpers/custom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { toast } from "react-toastify";

const GET_PRODUCTS = gql`
	query products(
		$category: String!
		$indexFrom: Int!
		$limit: Int!
		$sortBy: String
		$store: String
		$store_name: String
		$title: String
		$status: String
	) {
		products(
			category: $category
			indexFrom: $indexFrom
			limit: $limit
			sortBy: $sortBy
			store: $store
			store_name: $store_name
			title: $title
			status: $status
		) {
			total
			hasMore
			items {
				id
				title
				description
				brand
				category
				price
				new
				tipe
				sale
				stock
				discount
				slug
				sold
				sisa
				status
				highlight
				variants {
					id
					title
					description
					stock
					price
					image
					weight
				}
				images {
					image_id
					id
					alt
					src
				}
				store {
					name
					slug
					ref_cities_list_name
				}
			}
		}
	}
`;

const GET_STORE = gql`
	query ($slug: String!) {
		store(slug: $slug) {
			id
			name
			slug
			description
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			image
			created_date
			status
			total_product
			total_transaction_paid
			total_transaction_otw
			total_transaction_finish
			nama_rekening
			no_rekening
			ref_bank_id
			cabang_bank
			geoloc
			is_free_shipping
		}
	}
`;

const UPDATE_STORE = gql`
	mutation updateStore($input: UpdateStoreInput) {
		updateStore(input: $input) {
			success
			message
		}
	}
`;

const Product_list = () => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const sizePerPage = 10;
	const user = JSON.parse(localStorage.getItem("user"));
	const hideStore = !user.stores_id ? false : true;
	const [sortBy, setSortBy] = useState("");
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState({
		store_name: "",
		category: "",
		title: "",
		status: "",
	});
	const [defaultSorted, setDefaultSorted] = useState([
		{
			dataField: "id",
			order: "desc",
		},
	]);

	const columns = [
		{
			dataField: "id",
			text: "#",
			sort: true,
			headerStyle: () => ({ width: "5%" }),
		},
		{
			dataField: "stores_name",
			text: "Toko",
			sort: true,
			hidden: hideStore,
			formatter: (cell, row) => row.store[0].name,
		},
		{
			dataField: "category",
			text: "Kategori",
			sort: true,
		},
		{
			dataField: "tipe",
			text: "Tipe",
			sort: true,
		},
		{
			dataField: "title",
			text: "Nama",
			sort: true,
		},
		{
			dataField: "stock",
			text: "Stok",
			sort: true,
			headerStyle: () => ({ width: "7%" }),
		},
		{
			dataField: "sold",
			text: "Terjual",
			sort: true,
			headerStyle: () => ({ width: "8%" }),
		},
		{
			dataField: "sisa",
			text: "Sisa",
			sort: true,
			headerStyle: () => ({ width: "7%" }),
		},
		{
			dataField: "price",
			text: "Harga",
			sort: true,
			formatter: (cell, row) => numberFormat(parseInt(row.price)),
			headerStyle: () => ({ width: "12%" }),
		},
		{
			dataField: "status",
			text: "Status",
			sort: true,
			headerStyle: () => ({ width: "8%" }),
		},
	];

	// Define Highlight column
	const highlightColumn = {
		dataField: "highlight",
		text: "Highlight",
		sort: true,
		formatter: (cell, row) => (row.highlight === "1" ? "Yes" : "No"),
		headerStyle: () => ({ width: "9%" }),
	};

	// If Admin, insert "Highlight" column after "Status"
	const finalColumns =
		user.privilege === "Admin"
			? [
					...columns.slice(
						0,
						columns.findIndex((col) => col.dataField === "status") + 1
					), // Up to "Status"
					highlightColumn, // Insert "Highlight"
					...columns.slice(
						columns.findIndex((col) => col.dataField === "status") + 1
					), // Remaining columns
			  ]
			: columns;

	// Add the action column at the end
	finalColumns.push({
		dataField: "",
		text: "",
		formatter: (cell, row) => (
			<div className="openbtn text-center">
				<Link to={`/produk/${row.slug}`}>
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: () => ({ width: "5%" }),
	});

	const columns_mobile = [
		{
			dataField: "id",
			text: "#",
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: "15%" };
			},
		},
		{
			dataField: "category",
			text: "Kategori",
			sort: true,
		},
		{
			dataField: "title",
			text: "Nama",
			sort: true,
		},
		{
			dataField: "",
			text: "",
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link to={`/produk/${row.slug}`}>
						<i className="fa fa-eye" title="Lihat" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: "5%" };
			},
		},
	];
	const expandRow = {
		renderer: (row) => (
			<>
				<div>{!user.stores_id ? "Toko: " + row.store[0].name : ""}</div>
				<div>Stock: {row.stock}</div>
				<div>Laku: {row.sold}</div>
				<div>Sisa: {row.sisa}</div>
				<div>Harga: {numberFormat(parseInt(row.price))}</div>
			</>
		),
	};

	var { data, refetch } = useQuery(GET_PRODUCTS, {
		variables: {
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			category: filter && filter.category ? filter.category : "",
			title: filter && filter.title ? filter.title : "",
			price: filter && filter.price ? filter.price : "",
			status: filter && filter.status ? filter.status.value : "",
			store_name: filter && filter.store_name ? filter.store_name : "",
			store: user.stores_slug ? user.stores_slug : "",
			sortBy: sortBy,
		},
	});

	const filterOptions = [
		{
			id: "store_name",
			label: "Store",
			value: filter.store_name,
			type: "text",
		},
		{
			id: "category",
			label: "Category",
			value: filter.category,
			type: "text",
		},
		{
			id: "title",
			label: "Product",
			value: filter.title,
			type: "text",
		},
		{
			id: "status",
			label: "Status",
			value: filter.status,
			type: "dropdown",
			options: [
				{
					label: "Active",
					value: "active",
				},
				{
					label: "Inactive",
					value: "inactive",
				},
			],
		},
	];

	const handleTableChange = (
		e,
		{ filters, page, sizePerPage, sortField, sortOrder, data }
	) => {
		if (page) {
			setPage(page);
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + "-" + sortOrder);
			setDefaultSorted([
				{
					dataField: sortField,
					order: sortOrder,
				},
			]);
		}
	};

	const handleFilterChange = (e) => {
		refetch({
			indexFrom: page * sizePerPage - sizePerPage,
			limit: sizePerPage,
			category: filter && filter.category ? filter.category : "",
			title: filter && filter.title ? filter.title : "",
			price: filter && filter.price ? filter.price : "",
			status: filter && filter.status ? filter.status.value : "",
			store_name: filter && filter.store_name ? filter.store_name : "",
			store: user.stores_slug ? user.stores_slug : "",
			sortBy: sortBy,
		});
		setFilter(e);
	};

	const [updateTodo] = useMutation(UPDATE_STORE);

	var { data: dataStore } = useQuery(GET_STORE, {
		variables: {
			slug: user.stores_slug ? user.stores_slug : "",
		},
	});

	const handleActivateFreeShipping = async (event) => {
		event.preventDefault();

		if (!dataStore || !dataStore.store) {
			toast.error("Store data not found.");
			return;
		}

		// Extract only the required fields for UpdateStoreInput
		const {
			id,
			name,
			description,
			email,
			phone,
			address,
			ref_cities_list_id,
			ref_province_list_id,
			ref_district_id,
			ref_postcode_id,
			is_free_shipping,
		} = dataStore.store;

		// Toggle is_free_shipping value
		const newFreeShippingValue = is_free_shipping === "1" ? "0" : "1";

		const updatedData = {
			id,
			name,
			description,
			email,
			phone,
			address,
			ref_cities_list_id,
			ref_province_list_id,
			ref_district_id,
			ref_postcode_id,
			is_free_shipping: newFreeShippingValue,
		};

		try {
			const result = await updateTodo({ variables: { input: updatedData } });

			if (result.data.updateStore.success) {
				toast.success(
					newFreeShippingValue === "1"
						? "Bebas Ongkir berhasil diaktifkan!"
						: "Bebas Ongkir berhasil dinonaktifkan!"
				);

				// ✅ Reload the page after 1.5 seconds
				setTimeout(() => {
					window.location.reload();
				}, 1500);
			} else {
				toast.error(result.data.updateStore.message);
			}
		} catch (error) {
			toast.error("Gagal memperbarui Bebas Ongkir.");
			console.error("Update error:", error);
		}
	};

	return (
		<Fragment>
			<Breadcrumb title="Produk" />
			<Container fluid={true}>
				{dataStore && dataStore.store ? (
					<Card>
						<CardBody>
							<h5 class="f-w-600 f-18 mb-4">Fitur Gratis Ongkir</h5>
							<Row>
								<Col lg="7">
									<Card className="card-outline">
										<CardBody>
											<div className="d-flex gap-3">
												<div className="icon">
													<ShoppingBag className="font-primary" />
												</div>
												<div className="text">
													<h5 className="mb-2">Gratis Ongkir</h5>
													<p className="mb-0">
														Fitur gratis ongkir dapat meningkatkan penjualan dan
														menarik calon pembeli
													</p>
													<div className="d-flex justify-content-star gap-2 mt-3">
														<Button
															color={
																dataStore.store.is_free_shipping === "1"
																	? "success"
																	: "primary"
															}
															className="d-flex gap-2"
															onClick={handleActivateFreeShipping}
														>
															{dataStore.store.is_free_shipping === "1" ? (
																<CheckSquare width={20} height={20} />
															) : (
																<X width={20} height={20} />
															)}
															<span>
																{dataStore.store.is_free_shipping === "1"
																	? "Aktif"
																	: "Tidak Aktif"}
															</span>
														</Button>
														<Link
															to={
																dataStore?.store?.is_free_shipping === "1"
																	? "/settings/free-shipping"
																	: "#"
															}
															className={`btn btn-info d-flex gap-2 ${
																dataStore?.store?.is_free_shipping === "0"
																	? "disabled-link"
																	: ""
															}`}
														>
															<Edit2 width={20} height={20} />
															Kelola Gratis Ongkir
														</Link>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</CardBody>
					</Card>
				) : (
					""
				)}
				<Card>
					<CardBody>
						<div className="d-flex justify-content-between">
							{/* {user.privilege === "Stores" && (
								<div className="btn-popup">
									<Link to="/settings/free-shipping" className="btn btn-info">
										Aktifkan Gratis Ongkir
									</Link>
								</div>
							)} */}
							<h5 class="f-w-600 f-18">List Produk</h5>
							<div className="btn-popup">
								<Link to="/produk/form/0" className="btn btn-primary">
									Tambah Produk
								</Link>
							</div>
						</div>
						<Filter
							filterOptions={filterOptions}
							filter={filter}
							handleFilterParentChange={handleFilterChange}
						/>
						<Row className="products-admin ratio_asos">
							{data && data.products ? (
								<BootstrapTable
									keyField="id"
									hover
									condensed
									remote={{ sort: true, pagination: true }}
									data={data && data.products ? data.products.items : []}
									columns={is_mobile ? columns_mobile : finalColumns}
									bordered={false}
									noDataIndication="Table is Empty"
									pagination={paginationFactory({
										sizePerPage: sizePerPage,
										page: page,
										hideSizePerPage: true,
										hidePageListOnlyOnePage: true,
										totalSize: data && data.products ? data.products.total : 0,
									})}
									defaultSorted={defaultSorted}
									onTableChange={handleTableChange}
									wrapperClasses="table-responsive"
									expandRow={is_mobile ? expandRow : ""}
								/>
							) : (
								""
							)}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Product_list;
