import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	CardHeader,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	FormFeedback,
} from "reactstrap";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const GET_INFO = gql`
	query ($slug: String!) {
		store(slug: $slug) {
			id
			name
			slug
			description
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			image
			created_date
			status
			total_product
			total_transaction_paid
			total_transaction_otw
			total_transaction_finish
			nama_rekening
			no_rekening
			ref_bank_id
			cabang_bank
			geoloc
			free_shipping {
				id
				store_id
				shipping_cost
				minimum_transaction
			}
		}
		getProvince {
			id
			name
			value
			label
			created_date
		}
	}
`;

const SETTING_SHIPPING = gql`
	mutation activateFreeShipping($input: FreeShippingInput) {
		activateFreeShipping(input: $input) {
			success
			message
		}
	}
`;

const UPDATE_FREE_SHIPPING = gql`
	mutation updateFreeShipping($input: FreeShippingInput) {
		updateFreeShipping(input: $input) {
			success
			message
		}
	}
`;

const FreeShipping = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const [activateFreeShipping] = useMutation(SETTING_SHIPPING);
	const [updateFreeShipping] = useMutation(UPDATE_FREE_SHIPPING);
	const [shippingCost, setShippingCost] = useState("0");
	const [minimumTransaction, setMinimumTransaction] = useState("0");
	const [errorMinimum, setErrorMinimum] = useState("");

	// Format number as Rupiah, default to "0" if empty
	const formatRupiah = (value) => {
		const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
		if (!numericValue) return "0"; // Return "0" if empty
		return `${parseInt(numericValue, 10).toLocaleString("id-ID")}`;
	};

	// Convert formatted "x.xxx.xxx" to number
	const parseNumber = (value) => {
		return parseInt(value.replace(/[^0-9]/g, ""), 10) || 0;
	};

	// Handle changes for shipping cost
	const handleShippingCostChange = (e) => {
		const newValue = formatRupiah(e.target.value);
		setShippingCost(newValue);
		validateTransaction(newValue, minimumTransaction);
	};

	// Handle changes for minimum transaction
	const handleMinimumTransactionChange = (e) => {
		const newValue = formatRupiah(e.target.value);
		setMinimumTransaction(newValue);
		validateTransaction(shippingCost, newValue);
	};

	// Validation for minimum transaction vs shipping cost
	const validateTransaction = (newShippingCost, newMinimumTransaction) => {
		const shippingCostValue = parseNumber(newShippingCost);
		const minimumTransactionValue = parseNumber(newMinimumTransaction);

		if (minimumTransactionValue <= shippingCostValue) {
			setErrorMinimum(
				"Minimal pembelian harus lebih besar dari nominal gratis ongkir!"
			);
		} else {
			setErrorMinimum("");
		}
	};

	var { data } = useQuery(GET_INFO, {
		variables: {
			slug: user.stores_slug ? user.stores_slug : "",
		},
	});

	useEffect(() => {
		if (!user || user.privilege === "Stores") {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (data && data.store && data.store.free_shipping) {
			setShippingCost(
				formatRupiah(
					data.store.free_shipping[0]?.shipping_cost?.toString() || "0"
				)
			);
			setMinimumTransaction(
				formatRupiah(
					data.store.free_shipping[0]?.minimum_transaction?.toString() || "0"
				)
			);
		}
	}, [data]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!data || !data.store) {
			toast.error("Store data not found.");
			return;
		}

		// Extract store ID and free shipping data
		const { id: store_id, free_shipping } = data.store;

		// Convert formatted values to numbers
		const shippingCostValue = parseNumber(shippingCost);
		const minimumTransactionValue = parseNumber(minimumTransaction);

		// Validate again before sending mutation
		if (minimumTransactionValue <= shippingCostValue) {
			toast.error(
				"Minimal pembelian harus lebih besar dari nominal gratis ongkir!"
			);
			return;
		}

		try {
			let mutation, inputData;

			if (free_shipping.length > 0) {
				// Use updateFreeShipping and send the existing free_shipping ID
				mutation = updateFreeShipping;
				inputData = {
					id: free_shipping[0].id, // Include the existing free_shipping ID
					store_id,
					shipping_cost: shippingCostValue,
					minimum_transaction: minimumTransactionValue,
				};
			} else {
				// Use activateFreeShipping for new entry
				mutation = activateFreeShipping;
				inputData = {
					store_id,
					shipping_cost: shippingCostValue,
					minimum_transaction: minimumTransactionValue,
				};
			}

			const result = await mutation({
				variables: {
					input: inputData,
				},
			});

			const success =
				result.data?.activateFreeShipping?.success ||
				result.data?.updateFreeShipping?.success;
			const message =
				result.data?.activateFreeShipping?.message ||
				result.data?.updateFreeShipping?.message;

			if (success) {
				toast.success("Bebas Ongkir berhasil diperbarui!");
			} else {
				toast.error(message || "Terjadi kesalahan.");
			}
		} catch (error) {
			toast.error("Gagal memperbarui Bebas Ongkir.");
			console.error("Mutation error:", error);
		}
	};

	return (
		<Fragment>
			<Breadcrumb title="Gratis Ongkir" />
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<h5>Setting Gratis Ongkir</h5>
					</CardHeader>
					<CardBody>
						<Row>
							<Col xl="8">
								<Form
									className="needs-validation"
									noValidate=""
									onSubmit={handleSubmit}
								>
									<FormGroup className="row">
										<Label className="col-xl-4 col-md-6">
											<span>*</span> Nominal Gratis Ongkir
										</Label>
										<div className="col-xl-8 col-md-7">
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text rounded-0 text-muted">
														Rp
													</div>
												</div>
												<Input
													className="form-control"
													id="shipping_cost"
													name="shipping_cost"
													type="text"
													required="required"
													placeholder="Masukkan nominal gratis ongkir"
													value={shippingCost}
													onChange={handleShippingCostChange}
												/>
											</div>
										</div>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-xl-4 col-md-6">
											<span>*</span> Minimal Pembelian
										</Label>
										<div className="col-xl-8 col-md-7">
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text rounded-0 text-muted">
														Rp
													</div>
												</div>
												<Input
													className={`form-control ${
														errorMinimum ? "is-invalid" : ""
													}`}
													id="minimum_transaction"
													name="minimum_transaction"
													type="text"
													required="required"
													placeholder="Masukkan minimal pembelian"
													value={minimumTransaction}
													onChange={handleMinimumTransactionChange}
												/>
												{errorMinimum && (
													<FormFeedback>{errorMinimum}</FormFeedback>
												)}
											</div>
										</div>
									</FormGroup>

									<div
										className="offset-xl-4 offset-sm-6"
										style={{ padding: "0 8px" }}
									>
										<Button
											type="submit"
											color="primary"
											disabled={!!errorMinimum}
										>
											Simpan
										</Button>
										<Link to="/produk" className="btn btn-light">
											Batal
										</Link>
									</div>
								</Form>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default FreeShipping;
