import React, { useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { numberFormat } from "../../../helpers/custom";

const columns = [
	{
		dataField: "id",
		text: "#",
		headerStyle: (colum, colIndex) => {
			return { width: "10%" };
		},
	},
	{
		dataField: "products_title",
		text: "Nama",
	},
	{
		dataField: "products_variant_title",
		text: "Variant",
	},
	{
		dataField: "qty",
		text: "Qty",
	},
	{
		dataField: "price",
		text: "Harga",
		formatter: (cell, row, rowIndex, extraData) =>
			numberFormat(parseInt(row.price)),
	},
	{
		dataField: "total",
		text: "Total",
		formatter: (cell, row, rowIndex, extraData) =>
			numberFormat(parseInt(row.total)),
		headerStyle: (colum, colIndex) => {
			return { width: "25%" };
		},
	},
];

const columns_mobile = [
	{
		dataField: "id",
		text: "",
		headerStyle: (colum, colIndex) => {
			return { width: "13%" };
		},
	},
	{
		dataField: "products_title",
		text: "",
		formatter: (cell, row, rowIndex, extraData) => (
			<>
				<div>
					<b>{row.products_title}</b>
				</div>
				<div>Varian: {row.products_variant_title}</div>
				<div>Qty: {row.qty}</div>
				<div>Harga: {numberFormat(parseInt(row.price))}</div>
			</>
		),
	},
	{
		dataField: "total",
		text: "Total",
		formatter: (cell, row, rowIndex, extraData) =>
			numberFormat(parseInt(row.total)),
	},
];
const TabsetProfile = ({ data }) => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const [serviceFee, setServiceFee] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [shipping, setShipping] = useState(0);
	const [shippingDiscount, setShippingDiscount] = useState(0);
	const [grandtotal, setGrandtotal] = useState(0);
	useEffect(() => {
		if (data && data.getOrder) {
			let subtotal = 0;
			data.getOrder.orderList.map((e) => {
				subtotal += parseInt(e.total);
			});
			setServiceFee(-data.getOrder.service_fee.replace("%", ""));
			setSubtotal(subtotal);
			setSubtotal(subtotal);
			setShipping(parseInt(data.getOrder.shipping_cost));
			setShippingDiscount(parseInt(data.getOrder.shipping_discount));
			setGrandtotal(
				parseInt(subtotal) -
					parseInt(
						(subtotal * data.getOrder.service_fee.replace("%", "")) / 100
					) +
					parseInt(data.getOrder.shipping_cost)
			);
		}
	}, [data]);

	return (
		<>
			{data && data.getOrder ? (
				<Card className="profile-card">
					<CardBody>
						<div>
							{data ? (
								<BootstrapTable
									keyField="id"
									hover
									condensed
									data={data && data.getOrder ? data.getOrder.orderList : []}
									columns={is_mobile ? columns_mobile : columns}
									bordered={false}
									noDataIndication="Table is Empty"
									wrapperClasses="table-responsive"
								/>
							) : (
								""
							)}
						</div>
						<div className="d-flex">
							<span className="col-7 col-lg-9 p-2 text-end">
								Penjualan Barang
							</span>
							<span className="col-5 col-lg-3 p-2">
								{numberFormat(subtotal)}
							</span>
						</div>
						<div className="d-flex">
							<span className="col-7 col-lg-9 p-2 text-end ">Ongkos kirim</span>
							<span className="col-5 col-lg-3 p-2">
								{numberFormat(shipping)}
							</span>
						</div>
						<div className="d-flex">
							<span className="col-7 col-lg-9 p-2 text-end font-weight-bold">
								Subtotal Transaksi
							</span>
							<span className="col-5 col-lg-3 p-2 border-top font-weight-bold">
								{numberFormat(parseInt(subtotal + shipping))}
							</span>
						</div>
						<div className="d-flex">
							<span className="col-7 col-lg-9 p-2 text-end ">
								Biaya Layanan Toko{" "}
								<a
									href="https://bisabelanja.id/info-penting"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fa fa-question-circle" />
								</a>
							</span>
							<span className="col-5 col-lg-3 p-2">
								{numberFormat(parseInt((subtotal * serviceFee) / 100))}
							</span>
						</div>
						{shippingDiscount > 0 && (
							<div className="d-flex">
								<span className="col-7 col-lg-9 p-2 text-end ">
									Biaya Ongkir Gratis
								</span>
								<span className="col-5 col-lg-3 p-2">
									-{numberFormat(parseInt(shippingDiscount))}
								</span>
							</div>
						)}
						<div className="d-flex">
							<span className="col-7 col-lg-9 p-2 text-end font-weight-bold">
								Total Penjualan
							</span>
							<span className="col-5 col-lg-3 p-2 border-top font-weight-bold">
								{numberFormat(
									parseInt(subtotal + shipping) -
										(shippingDiscount > 0 ? shippingDiscount : 0) +
										(subtotal * serviceFee) / 100
								)}
							</span>
						</div>
					</CardBody>
				</Card>
			) : (
				""
			)}
		</>
	);
};

export default TabsetProfile;
