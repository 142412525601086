import {
	Home,
	Box,
	Tag,
	Package,
	ShoppingBag,
	Users,
	Layout,
	GitHub,
	MessageSquare,
	DollarSign,
	Settings,
	Airplay,
	BarChart,
} from "react-feather";

export const MENUITEMS = [
	{
		path: "/dashboard",
		title: "Dashboard",
		icon: Home,
		type: "link",
		badgeType: "primary",
		active: false,
	},
	{
		title: "Reports",
		icon: BarChart,
		type: "sub",
		active: false,
		children: [
			{ path: "/reports/income", title: "Income", type: "link" },
			{ path: "/reports/expense", title: "Expense", type: "link" },
		],
	},
	{
		title: "Refund",
		icon: ShoppingBag,
		path: "/refund",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Order",
		icon: ShoppingBag,
		path: "/order",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Checkout Link",
		icon: ShoppingBag,
		path: "/checkout-link",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		path: "/saldo/withdraw",
		title: "Balance Withdrawal",
		icon: DollarSign,
		type: "link",
		badgeType: "primary",
		active: false,
	},
	{
		title: "Produk",
		icon: Package,
		type: "sub",
		active: false,
		children: [
			{ path: "/produk/kategori", title: "Kategori", type: "link" },
			{ path: "/produk/subkategori", title: "Subkategori", type: "link" },
			{ path: "/produk", title: "Daftar Produk", type: "link" },
		],
	},
	{
		title: "Toko",
		icon: Airplay,
		path: "/toko",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Pendaftaran Toko",
		icon: Airplay,
		path: "/pendaftaran-toko",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Customer",
		icon: Users,
		path: "/customer",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Whatsapp",
		icon: MessageSquare,
		path: "/whatsapp",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Admin",
		icon: GitHub,
		path: "/users",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	/*{
        path: '/shipping', title: 'Shipping', icon: Home, type: 'link', badgeType: 'primary', active: false
    },*/
	{
		title: "Banner",
		icon: Layout,
		path: "/banner",
		type: "link",
		active: false,
		privilege: "Admin",
	},
	{
		title: "Settings",
		icon: Settings,
		type: "sub",
		active: false,
		children: [
			{ path: "/settings/shipping", title: "Shipping", type: "link" },
			{ path: "/settings/service-fee", title: "Service Fee", type: "link" },
			{ path: "/settings/withdraw-fee", title: "Withdraw Fee", type: "link" },
			// { path: "/settings/free-shipping", title: "Gratis Ongkir", type: "link" },
		],
	},
	/*{
        title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
            { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
            { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
        ]
    },
    {
        title: 'Invoice', path: '/invoice', icon: Archive, type: 'link', active: false
    }*/
];
